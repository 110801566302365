/* global Vue, VueTheMask, fbq, ym, data */
import { TweenLite } from 'gsap';

export default function () {
	// Быстрая корзина
	if (document.querySelector('#cart')) {
		window.cart = new Vue({
			el: '#cart',
			data: {
				cart: [],
				cart2: [],
				also: [],
				total: '',
				deliverySum: '',
				totalSum: '',
				quantity: 0,
				delivery: 1,
				payment: 1,
				postcode: '',
				stop: false,
				stopCount: false,
			},
			mounted: function () {
				var self = this;
				try {
					self.deliverySum = '&mdash;';
					fetch('/ajax/cart', {
						headers: {
							'Content-Type': 'application/json; charset=utf-8'
						},
						method: 'POST',
						body: JSON.stringify({
							action: 'list',
							delivery: self.delivery,
							postcode: self.postcode
						})
					}).then(function (response) {
						return response.text().then(function (text) {
							var data = JSON.parse(text);
							self.cart = data.items;
							self.cart2 = data.items2;
							self.also = data.also;
							self.total = data.total;
							self.quantity = data.quantity;
							self.stop = data.stop;
							self.deliverySum = data.deliverysum;
							self.totalSum = data.totalsum;

							document.querySelector('.modal_cart .modal_head__title font').innerHTML = parseInt(data.quantity, 0);
						});
					});
				} catch (error) {
					console.log(error);
				}
			},
			methods: {
				list: function (event) {
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch('/ajax/cart', {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: 'POST',
							body: JSON.stringify({
								action: 'list',
								delivery: self.delivery,
								postcode: self.postcode
							})
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								self.cart = data.items;
								self.cart2 = data.items2;
								self.also = data.also;
								self.total = data.total;
								self.quantity = data.quantity;
								self.stop = data.stop;
								self.deliverySum = data.deliverysum;
								self.totalSum = data.totalsum;

								if (window.order1) {
									if (data.deliveryCourier !== undefined) {
										window.order1.deliveryCourier = data.deliveryCourier;
									}
									if (data.deliveryExpressCourier !== undefined) {
										window.order1.deliveryExpressCourier = data.deliveryExpressCourier;
									}
									if (data.deliveryPoint !== undefined) {
										window.order1.deliveryPoint = data.deliveryPoint;
									}
									if (data.dateCourier !== undefined) {
										window.order1.calc = true;
										window.order1.dateCourier = data.dateCourier;
									}
									if (data.dateExpressCourier !== undefined) {
										window.order1.calc = true;
										window.order1.dateExpressCourier = data.dateExpressCourier;
									}
									if (data.datePoint !== undefined) {
										window.order1.calc = true;
										window.order1.datePoint = data.datePoint;
									}
								}

								document.querySelector('.modal_cart .modal_head__title font').innerHTML = parseInt(data.quantity, 0);
							});
						});
					} catch (error) {
						console.log(error);
					}
				},
				minus: function (event) {
					var self = this;
					var id = event.target.dataset.id;
					var size = event.target.dataset.size;
					var value = event.target.closest('.preview__content').querySelector('font').innerHTML;

					if (value > 1) {
						value--;
						event.target.closest('.preview__content').querySelector('font').innerHTML = value;
						try {
							self.deliverySum = '&mdash;';
							fetch('/ajax/cart', {
								headers: {
									'Content-Type': 'application/json; charset=utf-8'
								},
								method: 'POST',
								body: JSON.stringify({
									action: 'minus',
									delivery: self.delivery,
									postcode: self.postcode,
									quantity: value,
									id: id,
									size: size,
								})
							}).then(function (response) {
								return response.text().then(function (text) {
									var data = JSON.parse(text);
									self.cart = data.items;
									self.cart2 = data.items2;
									self.also = data.also;
									self.total = data.total;
									self.quantity = data.quantity;
									self.stop = data.stop;
									self.deliverySum = data.deliverysum;
									self.totalSum = data.totalsum;

									document.querySelector('.modal_cart .modal_head__title font').innerHTML = parseInt(data.quantity, 0);

									try {
										window.dataLayer.push(data.dataLayer);
									} catch (error) {
										console.log(error);
									}
								});
							});
						} catch (error) {
							console.log(error);
						}
					}
					event.preventDefault();
				},
				plus: function (event) {
					var self = this;
					var id = event.target.dataset.id;
					var size = event.target.dataset.size;
					var value = event.target.closest('.preview__content').querySelector('font').innerHTML;
					value++;
					event.target.closest('.preview__content').querySelector('font').innerHTML = value;

					try {
						self.deliverySum = '&mdash;';
						fetch('/ajax/cart', {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: 'POST',
							body: JSON.stringify({
								action: 'plus',
								delivery: self.delivery,
								postcode: self.postcode,
								quantity: value,
								size: size,
								id: id
							})
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								self.cart = data.items;
								self.cart2 = data.items2;
								self.also = data.also;
								self.total = data.total;
								self.quantity = data.quantity;
								self.stop = data.stop;
								self.deliverySum = data.deliverysum;
								self.totalSum = data.totalsum;

								document.querySelector('.modal_cart .modal_head__title font').innerHTML = parseInt(data.quantity, 0);

								try {
									window.dataLayer.push(data.dataLayer);
								} catch (error) {
									console.log(error);
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
					event.preventDefault();
				},
				remove: function (event) {
					var self = this;
					var id = event.target.dataset.id;
					var size = event.target.dataset.size;

					if (size === undefined) {
						size = 'one size';
					}

					// var value = event.target.closest('.preview__content').querySelector('font').innerHTML;
					// value--;
					// event.target.parentNode.closest('.preview__content').querySelector('font').innerHTML = value;

					try {
						self.deliverySum = '&mdash;';
						fetch('/ajax/cart', {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: 'POST',
							body: JSON.stringify({
								action: 'remove',
								delivery: self.delivery,
								postcode: self.postcode,
								size: size,
								id: id
							})
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								self.cart = data.items;
								self.cart2 = data.items2;
								self.also = data.also;
								self.total = data.total;
								self.quantity = data.quantity;
								self.stop = data.stop;
								self.deliverySum = data.deliverysum;
								self.totalSum = data.totalsum;

								document.querySelector('.modal_cart .modal_head__title font').innerHTML = parseInt(data.quantity, 0);

								try {
									window.dataLayer.push(data.dataLayer);
								} catch (error) {
									console.log(error);
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
					event.preventDefault();
				}
			}
		});
	}

	if (document.querySelector('.js-size')) {
		document.querySelectorAll('.js-size').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();

				let size = e.target.dataset.size;

				document.querySelectorAll('.js-size').forEach(function (item2) {
					item2.classList.remove('is-active');
				});
				document.querySelectorAll('.unit__action').forEach(function (item3) {
					item3.classList.remove('active');
				});
				document.querySelector('.unit__action[data-size="' + size + '"]').classList.add('active');
				e.target.classList.add('is-active');
			};
		});
	}

	if (document.querySelector('.js-cart')) {
		document.querySelectorAll('.js-cart').forEach(function (item) {
			item.onclick = function (e) {
				// get variables
				var id = e.target.dataset.id;
				var size = e.target.dataset.size;
				var monoparam = '';

				if (size === undefined) {
					size = 'one size';
				}

				// monochrome id
				if (document.querySelector('#monoparam')) {
					monoparam = document.querySelector('#monoparam').value;
				}

				// ajax
				try {
					fetch('/ajax/cart', {
						headers: {
							'Content-Type': 'application/json; charset=utf-8'
						},
						method: 'POST',
						body: JSON.stringify({
							action: 'add',
							id: id,
							size: size,
							monoparam: monoparam
						})
					}).then(function (response) {
						return response.text().then(function (text) {
							var data = JSON.parse(text);

							// giftbox
							if (data.giftbox) {
								document.querySelector('body').classList.add('is-giftbox');
							} else {
								setTimeout(function () {
									window.cart.list();
									TweenLite.from(document.querySelector('.modal_cart .modal__bg'), {opacity: 0, delay: 0, onComplete: function () {
										TweenLite.to(document.querySelector('.modal_cart .modal__bg'), 0.3, {opacity: 1, delay: 0});
									}});

									if (window.matchMedia('screen and (max-width: 767px)').matches) {
										TweenLite.from(document.querySelector('.modal_cart .modal__container'), {y: '100%', x: 0, delay: 0, onComplete: function () {
											TweenLite.to(document.querySelector('.modal_cart .modal__container'), 0.3, {y: '0%', x: 0, delay: 0});
										}});
									} else {
										TweenLite.from(document.querySelector('.modal_cart .modal__container'), {x: '100%', y: 0, delay: 0, onComplete: function () {
											TweenLite.to(document.querySelector('.modal_cart .modal__container'), 0.3, {x: '0%', y: 0, delay: 0});
										}});
									}
									document.querySelector('body').classList.add('is-cart');
								}, 100);
							}

							try {
								window.dataLayer.push(data.dataLayer);
							} catch (error) {
								console.log(error);
							}
						});
					});
					try {
						ym(64597318, 'reachGoal', 'addcart');
					} catch (error) {
						console.log(error);
					}
				} catch (error) {
					console.error('Error:', error);
				}
				return false;
			};
		});
	}
}
