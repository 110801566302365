/* global Vue, VueTheMask */
import { TweenLite } from 'gsap';
import iMask from 'imask';

export default function () {
	let startY;
	let modal;
	let m;

	var cart;

	function handleStart(evt) {
		// evt.preventDefault();

		let touches = evt.changedTouches;
		startY = touches[0].clientY;
	}

	function handleMove(evt) {
		// evt.preventDefault();

		let touches = evt.changedTouches;
		let actualY = touches[0].clientY;
		let height = modal.querySelector('.modal__container').offsetHeight;
		let opacity = 1 - ((actualY - startY) / (height * 0.7));

		if (actualY > startY) {
			TweenLite.to(modal.querySelector('.modal__container'), 0, {y: (-(startY - actualY))});
			TweenLite.to(modal.querySelector('.modal__bg'), 0, {opacity: opacity});
		} else {
			TweenLite.to(modal.querySelector('.modal__container'), 0, {y: (-(startY - actualY) / 5)});
			TweenLite.to(modal.querySelector('.modal__bg'), 0, {opacity: 1});
		}
	}

	function handleEnd(evt) {
		// evt.preventDefault();

		let touches = evt.changedTouches;
		let stopY = touches[0].clientY;
		let height = modal.querySelector('.modal__container').offsetHeight;

		if (stopY < startY) {
			TweenLite.to(modal.querySelector('.modal__container'), 0.3, {y: '0px'});
		} else {
			if (stopY - startY > height / 4) {
				TweenLite.to(modal.querySelector('.modal__container'), 0.3, {y: '100%'});
				TweenLite.to(modal.querySelector('.modal__bg'), 0.3, {opacity: 0, onComplete: function () {
					document.querySelector('body').classList.remove('is-' + m);
				}});
			} else {
				TweenLite.to(modal.querySelector('.modal__container'), 0.3, {y: 0});
			}
		}
	}

	var monoidPrice = 0;

	// mono id
	if (document.querySelector('.monoid')) {
		var fonter = 1;

		document.querySelector('.monoid input[name=letters]').onkeyup = function (e) {
			var that = this;
			var price = 0;
			setTimeout(function () {
				var res;
				var bef = that.value;
				if (fonter === 2) {
					res = /[^а-я]/g.exec(that.value);
					document.querySelector('.monoid input[name=letters]').placeholder = 'АБВГ';
				} else {
					res = /[^a-z]/g.exec(that.value);
					document.querySelector('.monoid input[name=letters]').placeholder = 'ABCD';
				}
				price = 0;
				that.value = that.value.replace(res, '');
				if (that.value.length > 4 && that.value.length <= 8) {
					if (that.value !== 'peace') {
						price = 2000;
					}
				}
				if (that.value.length > 8) {
					price = 4000;
				}
				if (price > 0) {
					document.querySelector('.js-monoid-save .btn__label span').innerHTML = '+' + price + '₽';
				} else {
					document.querySelector('.js-monoid-save .btn__label span').innerHTML = '';
				}
				that.value.substring(0, 12);
				if (bef !== res) {
					document.querySelector('.monoerr').classList.add('monoerr-active');
				}

				monoidPrice = price;
			}, 10);
		};

		document.querySelectorAll('.monoid_field__color').forEach(function (item) {
			item.onclick = function (e) {
				var color = e.target.dataset.color;
				if (document.querySelector('.monoid_field__color.is-active')) {
					document.querySelector('.monoid_field__color.is-active').classList.remove('is-active');
				}
				e.target.classList.add('is-active');
				document.querySelector('#field_color span').innerHTML = color;
				e.preventDefault();
			};
		});
		document.querySelectorAll('.monoid_field__place').forEach(function (item) {
			item.onclick = function (e) {
				var place = e.target.dataset.place;
				if (document.querySelector('.monoid_field__place.is-active')) {
					document.querySelector('.monoid_field__place.is-active').classList.remove('is-active');
				}
				e.target.classList.add('is-active');
				document.querySelector('#field_place span').innerHTML = place;
				e.preventDefault();
			};
		});
		document.querySelectorAll('.monoid_field__font').forEach(function (item) {
			item.onclick = function (e) {
				var that = this;
				setTimeout(function () {
					var font = e.target.dataset.font;
					if (document.querySelector('.monoid_field__font.is-active')) {
						document.querySelector('.monoid_field__font.is-active').classList.remove('is-active');
					}

					if (font === '2') {
						fonter = 2;
						document.querySelector('.font-hint span').innerHTML = 'Только русские буквы';
					} else {
						fonter = 1;
						document.querySelector('.font-hint span').innerHTML = 'Только латинские буквы';
					}

					var res;
					if (fonter === 2) {
						res = /[^а-я]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'АБВГ';
					} else {
						res = /[^a-z]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'ABCD';
					}
					document.querySelector('.monoid input[name=letters]').value = document.querySelector('.monoid input[name=letters]').value.replace(res, '');

					if (fonter === 2) {
						res = /[^а-я]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'АБВГ';
					} else {
						res = /[^a-z]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'ABCD';
					}
					document.querySelector('.monoid input[name=letters]').value = document.querySelector('.monoid input[name=letters]').value.replace(res, '');

					if (fonter === 2) {
						res = /[^а-я]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'АБВГ';
					} else {
						res = /[^a-z]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'ABCD';
					}
					document.querySelector('.monoid input[name=letters]').value = document.querySelector('.monoid input[name=letters]').value.replace(res, '');

					if (fonter === 2) {
						res = /[^а-я]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'АБВГ';
					} else {
						res = /[^a-z]/g.exec(document.querySelector('.monoid input[name=letters]').value);
						document.querySelector('.monoid input[name=letters]').placeholder = 'ABCD';
					}
					document.querySelector('.monoid input[name=letters]').value = document.querySelector('.monoid input[name=letters]').value.replace(res, '');

					e.target.classList.add('is-active');
					document.querySelector('#field_font span').innerHTML = font;
				}, 10);
				e.preventDefault();
			};
		});
		document.querySelector('.js-monoid-save').onclick = function (e) {
			var color = document.querySelector('#field_color span').innerHTML;
			var place = document.querySelector('#field_place span').innerHTML;
			var font = document.querySelector('#field_font span').innerHTML;
			var letters = document.querySelector('.monoid input[name=letters]').value;
			var error = '';

			if (letters === '') {
				error = 'введите буквы';
				document.querySelector('#field_letters').style.color = 'red';
			} else {
				document.querySelector('#field_letters').style.color = '';
			}

			if (font === 'не выбрано') {
				error = 'выберите шрифт';
				document.querySelector('#field_font span').style.color = 'red';
			} else {
				document.querySelector('#field_font span').style.color = '';
			}

			if (place === 'не выбрано') {
				error = 'выберите область вышивки';
				document.querySelector('#field_place span').style.color = 'red';
			} else {
				document.querySelector('#field_place span').style.color = '';
			}

			if (color === 'не выбрано') {
				error = 'выберите цвет ниток';
				document.querySelector('#field_color span').style.color = 'red';
			} else {
				document.querySelector('#field_color span').style.color = '';
			}

			if (error !== '') {
				document.querySelector('.monoid-error').style.display = 'block';
				document.querySelector('.monoid-error').innerHTML = error;
			} else {
				var monoValue = document.querySelector('.monoid input[name=letters]').value;
				if (monoidPrice > 0) {
					monoValue += ' ' + ' (+' + monoidPrice + '₽)';
				}
				document.querySelector('.monoid-error').style.display = 'none';
				document.querySelector('body').classList.remove('is-monoid');
				document.querySelector('#monoparam').value = color + '|' + place + '|' + font + '|' + letters;
				document.querySelector('.monoid-label').innerHTML = monoValue;
			}
			e.preventDefault();
		};

		document.querySelector('.js-monoid-clear').onclick = function (e) {
			document.querySelector('body').classList.remove('is-monoid');
			document.querySelector('#monoparam').value = '';
			document.querySelector('.monoid-label').innerHTML = document.querySelector('.monoid-label').dataset.value;
			e.preventDefault();
		};
	}

	// goods
	if (document.querySelector('#goods')) {
		let goodsAction = document.querySelector('#goods').dataset.action;
		let goodsMethod = document.querySelector('#goods').dataset.method;

		var goods = new Vue({
			el: '#goods',
			data: {
				id: 0,
				image: '',
				goods: '',
			},
			mounted: function () {
				//
			},
			methods: {
				load: function (e) {
					var self = this;
					try {
						fetch(goodsAction, {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: goodsMethod,
							body: (goodsMethod === 'POST') ? JSON.stringify({
								id: self.id
							}) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								self.image = data.image;
								self.goods = data.goods;
							});
						});
					} catch (error) {
						console.log(error);
					}
				}
			}
		});
	}

	// orders
	if (document.querySelector('#order')) {
		let orderAction = document.querySelector('#order').dataset.action;
		let orderMethod = document.querySelector('#order').dataset.method;

		console.log(document.querySelector('#order'));

		var order = new Vue({
			el: '#order',
			data: {
				id: 0,
				order: []
			},
			mounted: function () {
				//
			},
			methods: {
				load: function (e) {
					var self = this;
					try {
						fetch(orderAction, {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: orderMethod,
							body: (orderMethod === 'POST') ? JSON.stringify({
								id: self.id
							}) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								self.order = data.order;
							});
						});
					} catch (error) {
						console.log(error);
					}
				}
			}
		});
	}

	// filters
	if (document.querySelector('#filters')) {
		document.querySelectorAll('.js-filter-toggle').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();
				var id = e.target.dataset.id;
				var param = e.target.dataset.param;
				if (document.querySelector('#' + param + id)) {
					document.querySelector('#' + param + id).remove();
					e.target.classList.remove('active');
				} else {
					let input = document.createElement('input');
					input.setAttribute('type', 'hidden');
					input.setAttribute('value', id);
					input.setAttribute('name', param + '[]');
					input.id = param + id;
					document.querySelector('#filters').append(input);
					e.target.classList.add('active');
				}
			};
		});
		document.querySelectorAll('.js-filter-change').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();
				var id = e.target.dataset.id;
				var param = e.target.dataset.param;
				document.querySelector('input[name="' + param + '"]').value = id;
				document.querySelectorAll('.js-filter-change[data-param="' + param + '"]').forEach(function (item2) {
					item2.classList.remove('active');
				});
				e.target.classList.add('active');
			};
		});
	}

	if (document.querySelector('.js-modal')) {
		document.querySelectorAll('.js-modal').forEach(function (item) {
			item.onclick = function (e) {
				m = e.target.dataset.modal;
				e.preventDefault();

				if (m === 'goods') {
					goods.id = e.target.dataset.id;
					goods.load();
				}

				if (m === 'reserve') {
					document.querySelector('body').classList.remove('is-avail');
					document.querySelector('body').classList.remove('is-modal');
					document.querySelector('.modal_reserve input[name="store_id"]').value = e.target.dataset.store;
					document.querySelector('.modal_reserve input[name="product_id"]').value = e.target.dataset.product;
					document.querySelector('.modal_reserve input[name="size_id"]').value = e.target.dataset.size;
				}

				if (m === 'wantgift') {
					document.querySelector('.modal_wantgift input[name="product_id"]').value = e.target.dataset.product;
				}

				if (m === 'preorder') {
					document.querySelector('.modal_preorder input[name="product_id"]').value = e.target.dataset.product;
					if (e.target.dataset.size) {
						document.querySelector('.modal_preorder input[name="size_id"]').value = e.target.dataset.size;
					} else {
						document.querySelector('.modal_preorder input[name="size_id"]').value = '0';
					}
				}

				if (m === 'order') {
					order.id = e.target.dataset.id;
					if (e.target.dataset.number) {
						document.querySelector('.modal_order .modal_head__title font').innerHTML = e.target.dataset.number;
						document.querySelector('.modal_order .modal_head__title span').innerHTML = e.target.dataset.date;
					}
					order.load();
				}

				if (document.querySelector('body').classList.contains('is-' + m)) {
					modal = document.querySelector('.modal_' + m);
					if (modal.classList.contains('modal--pull-mobile')) {
						modal.removeEventListener('touchstart', handleStart, false);
						modal.removeEventListener('touchmove', handleMove, false);
						modal.removeEventListener('touchend', handleEnd, false);
					}

					TweenLite.from(document.querySelector('.modal_' + m + ' .modal__bg'), {opacity: 1, delay: 0, onComplete: function () {
						TweenLite.to(document.querySelector('.modal_' + m + ' .modal__bg'), 0.3, {opacity: 0, delay: 0});
					}});

					if (window.matchMedia('screen and (max-width: 767px)').matches) {
						TweenLite.from(document.querySelector('.modal_' + m + ' .modal__container'), {y: '0%', x: 0, delay: 0, onComplete: function () {
							TweenLite.to(document.querySelector('.modal_' + m + ' .modal__container'), 0.3, {y: '100%', x: 0, delay: 0, onComplete: function () {
								document.querySelector('body').classList.remove('is-' + m);
							}});
						}});
					} else {
						TweenLite.from(document.querySelector('.modal_' + m + ' .modal__container'), {x: '0%', y: 0, delay: 0, onComplete: function () {
							TweenLite.to(document.querySelector('.modal_' + m + ' .modal__container'), 0.3, {x: '100%', y: 0, delay: 0, onComplete: function () {
								document.querySelector('body').classList.remove('is-' + m);
							}});
						}});
					}
				} else {
					modal = document.querySelector('.modal_' + m);
					if (modal.classList.contains('modal--pull-mobile')) {
						modal.addEventListener('touchstart', handleStart, false);
						modal.addEventListener('touchmove', handleMove, false);
						modal.addEventListener('touchend', handleEnd, false);
					}

					TweenLite.from(document.querySelector('.modal_' + m + ' .modal__bg'), {opacity: 0, delay: 0, onComplete: function () {
						TweenLite.to(document.querySelector('.modal_' + m + ' .modal__bg'), 0.3, {opacity: 1, delay: 0});
					}});

					if (window.matchMedia('screen and (max-width: 767px)').matches) {
						TweenLite.from(document.querySelector('.modal_' + m + ' .modal__container'), {y: '100%', x: 0, delay: 0, onComplete: function () {
							TweenLite.to(document.querySelector('.modal_' + m + ' .modal__container'), 0.3, {y: '0%', x: 0, delay: 0});
						}});
					} else {
						TweenLite.from(document.querySelector('.modal_' + m + ' .modal__container'), {x: '100%', y: 0, delay: 0, onComplete: function () {
							TweenLite.to(document.querySelector('.modal_' + m + ' .modal__container'), 0.3, {x: '0%', y: 0, delay: 0});
						}});
					}

					document.querySelector('body').classList.add('is-' + m);
				}
			};
		});
	}

	Vue.use(VueTheMask);

	// search
	if (document.querySelector('#search')) {
		var search = new Vue({
			el: '#search',
			data: {
				action: 'search',
				search: '',
				firstLoad: true,
				categories: [],
				text: [],
				goods: []
			},
			mounted: function () {
				this.ajaxSearch();
			},
			methods: {
				ajaxSearch: function (e) {
					let form = document.querySelector('#search form');
					let self = this;
					try {
						fetch(form.dataset.action, {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: form.dataset.method,
							body: (form.dataset.method === 'POST') ? JSON.stringify({
								search: self.search
							}) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								let data = JSON.parse(text);
								self.firstLoad = data.firstLoad;
								self.categories = data.categories;
								self.text = data.text;
								self.goods = data.goods;
							});
						});
					} catch (error) {
						console.log(error);
					}
				}
			}
		});
	}

	// // cart
	// if (document.querySelector('#cart')) {
	// 	cart = new Vue({
	// 		el: '#cart',
	// 		data: {
	// 			goods: [],
	// 			unavailable: [],
	// 			also: []
	// 		},
	// 		mounted: function () {
	// 			this.list();
	// 		},
	// 		methods: {
	// 			list: function (e) {
	// 				let form = document.querySelector('#cart form');
	// 				let self = this;
	// 				try {
	// 					fetch(form.dataset.action, {
	// 						headers: {
	// 							'Content-Type': 'application/json; charset=utf-8'
	// 						},
	// 						method: form.dataset.method,
	// 						body: (form.dataset.method === 'POST') ? JSON.stringify({
	// 							search: self.search
	// 						}) : null
	// 					}).then(function (response) {
	// 						return response.text().then(function (text) {
	// 							let data = JSON.parse(text);
	// 							self.goods = data.goods;
	// 							self.unavailable = data.unavailable;
	// 							self.also = data.also;
	// 						});
	// 					});
	// 				} catch (error) {
	// 					console.log(error);
	// 				}
	// 			}
	// 		}
	// 	});
	// }

	// auth
	if (document.querySelector('#auth')) {
		var auth = new Vue({
			el: '#auth',
			data: {
				action: 'auth',
				name: '',
				email: ''
			},
			mounted: function () {
				//
			},
			methods: {
				auth: function (e) {
					e.preventDefault();

					this.action = 'auth';
				},
				submitAuth: function (e) {
					e.preventDefault();

					var form = e.target;
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch(form.getAttribute('action'), {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: form.getAttribute('method'),
							body: (form.getAttribute('method') === 'POST') ? JSON.stringify({
								email: self.email
							}) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								if (data.isGuest === true) {
									self.action = 'signup';
								} else {
									self.action = 'signin';
									self.name = data.name;
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
				},
				submitSignup: function (e) {
					e.preventDefault();

					var form = e.target;
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch(form.getAttribute('action'), {
							method: form.getAttribute('method'),
							body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								if (data.error === false) {
									document.location = data.link;
								} else {
									form.querySelectorAll('.field').forEach(function (field) {
										field.classList.remove('field--error');
									});
									form.querySelector('#' + data.errorField).closest('.field').classList.add('field--error');
									form.querySelector('#' + data.errorField).closest('.field').querySelector('.field__error').innerHTML = data.errorText;
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
				},
				submitSignin: function (e) {
					e.preventDefault();

					var form = e.target;
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch(form.getAttribute('action'), {
							method: form.getAttribute('method'),
							body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								if (data.error === false) {
									document.location = data.link;
								} else {
									form.querySelectorAll('.field').forEach(function (field) {
										field.classList.remove('field--error');
									});
									form.querySelector('#' + data.errorField).closest('.field').classList.add('field--error');
									if (form.querySelector('#' + data.errorField).closest('.field').querySelector('.field__error')) {
										form.querySelector('#' + data.errorField).closest('.field').querySelector('.field__error').innerHTML = data.errorText;
									}
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
				}
			}
		});
	}

	// order auth
	if (document.querySelector('#orderauth')) {
		var orderauth = new Vue({
			el: '#orderauth',
			data: {
				action: 'auth',
				name: '',
				email: ''
			},
			mounted: function () {
				//
			},
			methods: {
				submitAuth: function (e) {
					e.preventDefault();

					var form = e.target;
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch(form.getAttribute('action'), {
							headers: {
								'Content-Type': 'application/json; charset=utf-8'
							},
							method: form.getAttribute('method'),
							body: (form.getAttribute('method') === 'POST') ? JSON.stringify({
								email: self.email
							}) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								if (data.isGuest === true) {
									self.action = 'signup';
								} else {
									self.action = 'signin';
									self.name = data.name;
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
				},
				submitSignup: function (e) {
					e.preventDefault();

					var form = e.target;
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch(form.getAttribute('action'), {
							method: form.getAttribute('method'),
							body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								if (data.error === false) {
									document.location = data.link;
								} else {
									form.querySelectorAll('.field').forEach(function (field) {
										field.classList.remove('field--error');
									});
									form.querySelector('#' + data.errorField).closest('.field').classList.add('field--error');
									form.querySelector('#' + data.errorField).closest('.field').querySelector('.field__error').innerHTML = data.errorText;
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
				},
				submitSignin: function (e) {
					e.preventDefault();

					var form = e.target;
					var self = this;
					try {
						self.deliverySum = '&mdash;';
						fetch(form.getAttribute('action'), {
							method: form.getAttribute('method'),
							body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
						}).then(function (response) {
							return response.text().then(function (text) {
								var data = JSON.parse(text);
								if (data.error === false) {
									document.location = data.link;
								} else {
									form.querySelectorAll('.field').forEach(function (field) {
										field.classList.remove('field--error');
									});
									form.querySelector('#' + data.errorField).closest('.field').classList.add('field--error');
									if (form.querySelector('#' + data.errorField).closest('.field').querySelector('.field__error')) {
										form.querySelector('#' + data.errorField).closest('.field').querySelector('.field__error').innerHTML = data.errorText;
									}
								}
							});
						});
					} catch (error) {
						console.log(error);
					}
				}
			}
		});
	}

	if (document.querySelector('.form_reserve')) {
		document.querySelector('.form_reserve').onsubmit = function (e) {
			e.preventDefault();

			var form = e.target;
			var self = this;
			try {
				self.deliverySum = '&mdash;';
				fetch(form.getAttribute('action'), {
					method: form.getAttribute('method'),
					body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
				}).then(function (response) {
					return response.text().then(function (text) {
						var data = JSON.parse(text);
						if (data.error === false) {
							form.classList.add('is-sended');
						}
					});
				});
			} catch (error) {
				console.log(error);
			}
		};
	}

	if (document.querySelector('.form_preorder')) {
		document.querySelector('.form_preorder').onsubmit = function (e) {
			e.preventDefault();

			var form = e.target;
			var self = this;
			try {
				self.deliverySum = '&mdash;';
				fetch(form.getAttribute('action'), {
					method: form.getAttribute('method'),
					body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
				}).then(function (response) {
					return response.text().then(function (text) {
						var data = JSON.parse(text);
						if (data.error === false) {
							form.classList.add('is-sended');
						} else {
							if (data.error === 'duplicate') {
								form.classList.add('is-duplicate');
							}
						}
					});
				});
			} catch (error) {
				console.log(error);
			}
		};
	}

	if (document.querySelector('.form_wantgift')) {
		document.querySelector('.form_wantgift').onsubmit = function (e) {
			e.preventDefault();

			var form = e.target;
			var self = this;
			try {
				self.deliverySum = '&mdash;';
				fetch(form.getAttribute('action'), {
					method: form.getAttribute('method'),
					body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
				}).then(function (response) {
					return response.text().then(function (text) {
						var data = JSON.parse(text);
						if (data.error === false) {
							form.classList.add('is-sended');
						}
					});
				});
			} catch (error) {
				console.log(error);
			}
		};
	}

	if (document.querySelector('.subscribe form')) {
		document.querySelector('.subscribe form').onsubmit = function (e) {
			e.preventDefault();

			var form = e.target;
			var self = this;
			try {
				fetch(form.getAttribute('action'), {
					method: form.getAttribute('method'),
					body: (form.getAttribute('method') === 'POST') ? new FormData(form) : null
				}).then(function (response) {
					return response.text().then(function (text) {
						var data = JSON.parse(text);
						if (data.error === false) {
							form.classList.add('is-sended');
						}
					});
				});
			} catch (error) {
				console.log(error);
			}
		};
	}
}
