import Swiper from 'swiper/swiper-bundle.min.js';

export default function () {
	if (document.querySelector('.js-media-slider')) {
		document.querySelectorAll('.js-media-slider').forEach(function (item) {
			new Swiper(item,
				{
					slidesPerView: 'auto',
					navigation: {
						prevEl: '.swiper-prev',
						nextEl: '.swiper-next',
					},
					// on: {
					// 	slideChange: function (object) {
					// 		object.el.querySelector('.swiper_pages__current').innerHTML = object.realIndex + 1;
					// 		object.el.querySelector('.swiper_pages__total').innerHTML = object.slides.length;
					// 	}
					// }
				}
			);
		});
	}
}
