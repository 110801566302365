import iMask from 'imask';

export default function inputText() {
	if (document.querySelector('.input_text input') || document.querySelector('.textarea textarea')) {
		document.querySelectorAll('.input_text input, .textarea textarea').forEach(function (item) {
			item.onfocus = function (e) {
				e.target.parentNode.classList.add('input_text--focus');
				if (!e.target.value.length) {
					e.target.parentNode.classList.add('input_text--filled');
				}
			};
			item.onblur = function (e) {
				e.target.parentNode.classList.remove('input_text--focus');
				if (!e.target.value.length) {
					e.target.parentNode.classList.remove('input_text--filled');
				}
			};
			item.onkeyup = function (e) {
				var isValid = e.target.checkValidity();
				console.log(isValid);
				if (isValid) {
					e.target.parentNode.classList.add('input_text--valid');
					e.target.parentNode.classList.remove('input_text--invalid');
				} else {
					e.target.parentNode.classList.remove('input_text--valid');
					e.target.parentNode.classList.add('input_text--invalid');
				}
			};
		});
	}
	if (document.querySelector('input[name="phone"]')) {
		document.querySelectorAll('input[name="phone"]').forEach(function (element) {
			const maskOptions = {
				mask: [
					{
						mask: '+0 (000) 000-00-00',
						startsWith: '7',
						lazy: false,
						country: 'Russia'
					},
					{
						mask: '+000 00 000-00-00',
						startsWith: '3',
						lazy: false,
						country: 'Belarus'
					}
				],
				dispatch: (appended, dynamicMasked) => {
					const number = (dynamicMasked.value + appended).replace(/\D/g, '');
					return dynamicMasked.compiledMasks.find(m => number.indexOf(m.startsWith) === 0);
				}
			};
			iMask(element, maskOptions);
		});
	}
}
