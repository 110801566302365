export default function inputFile() {
	if (document.querySelector('.input_file input') || document.querySelector('.textarea textarea')) {
		document.querySelectorAll('.input_file input').forEach(function (item) {
			item.onchange = function (e) {
				let file = e.target.files[0];
				e.target.closest('label').querySelector('span').innerHTML = file.name;
			};
		});
	}
}
