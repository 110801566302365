export default function () {
	if (document.querySelector('.js-sustain')) {
		document.querySelectorAll('.js-sustain').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();
				var id = e.target.dataset.id;
				document.querySelector('body').classList.add('is-sustain');
				document.querySelector('body').classList.remove('nav-down');
				document.querySelector('body').classList.add('nav-up');
				document.querySelector('.sustainability_modal[data-id="' + id + '"]').classList.add('active');
			};
		});
	}
	if (document.querySelector('.js-sustain-close')) {
		document.querySelectorAll('.js-sustain-close').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();
				document.querySelector('body').classList.remove('is-sustain');
				document.querySelector('.sustainability_modal.active').classList.remove('active');
			};
		});
	}
	if (document.querySelector('.sustainability_modal__bg')) {
		document.querySelectorAll('.sustainability_modal__bg').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();
				document.querySelector('body').classList.remove('is-sustain');
				document.querySelector('.sustainability_modal.active').classList.remove('active');
			};
		});
	}
}
