'use strict';

import svgInject from 'static/js/helpers/svgInject';
import notification from 'components/header/notification';
import hero from 'components/content/hero/hero';
import modal from 'components/modal/modal';
import gallery from 'components/content/gallery/gallery';
import unit from 'components/blocks/unit/unit';
import aside from 'components/content/aside/aside';
import header from 'components/header/header';
import footer from 'components/footer/footer';
import media from 'components/blocks/media/media';
import giftcard from 'components/blocks/giftcard/giftcard';
import cart from 'components/blocks/cart/cart';
import inputFile from 'components/controls/input_file/input_file';
import inputText from 'components/controls/input_text/input_text';
import catalog from 'components/blocks/catalog/catalog';
import wishlist from 'components/unit/wishlist';
import slider from 'components/blocks/slider/slider';
import order from 'components/blocks/order/order';
import sustainability from 'components/blocks/sustainability/sustainability';

svgInject();
inputFile();
inputText();
notification();
hero();
modal();
gallery();
unit();
aside();
header();
footer();
media();
giftcard();
catalog();
wishlist();
slider();
cart();
order();
sustainability();

function videoStart() {
    document.querySelectorAll('.page__content video').forEach(function (videoElement) {
		if (videoElement.playing) {
			//
		} else {
			videoElement.play();
		}
	});
}

document.querySelectorAll('.page__content video').forEach(function (videoElement) {
	videoElement.onended = function () {
		videoElement.currentTime = 0.1;
		videoElement.play();
	};
});

setTimeout(function () {
	videoStart();
}, 100);

document.addEventListener('touchstart', videoStart, false);
document.addEventListener('load', videoStart, false);
document.addEventListener('DOMContentLoaded', videoStart, false);
