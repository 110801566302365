import Swiper from 'swiper/swiper-bundle.min.js';

export default function () {
	if (document.querySelector('.js-gallery')) {
		document.querySelectorAll('.js-gallery').forEach(function (item) {
			var swiper = new Swiper(item, {
				slidesPerView: 'auto',
				loop: true,
				navigation: {
					prevEl: '.swiper-left',
					nextEl: '.swiper-right',
				},
			});
		});
	}
}
