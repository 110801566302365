export default function () {
	if (document.querySelector('.page_notification')) {
		var notification = document.querySelector('.page_notification div:first-child');
		var clone;
		for (var x = 0; x < 3; x++) {
			clone = notification.cloneNode(true);
			clone.setAttribute('aria-hidden', true);
			notification.after(clone);
		}
	}
}
