import Swiper from 'swiper/swiper-bundle.min.js';

var shopSwiper;

export default function slider() {
	if (document.querySelector('.js-slider')) {
		shopSwiper = new Swiper('.js-slider', {
			wrapperClass: 'slider__wrapper',
			slideClass: 'slider__slide',
			spaceBetween: 1,
			slidesPerView: 'auto',
			centeredSlides: true,
			freeMode: true,
			// freeModeSticky: true,
			// freeModeMomentum: false,
			loop: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			grabCursor: true,
			speed: 500,
			// navigation: {
			// 	nextEl: '.slider__button--next',
			// 	prevEl: '.slider__button--prev',
			// },
			// on: {
			// 	slideChangeTransitionStart: function () {
			// 		this.params.speed = 500;
			// 		this.update();
			// 	}
			// }
		});

		document.querySelector('.js-slider').addEventListener('mouseenter', function (event) {
			shopSwiper.$el[0].classList.add('is-not-scrolling');

			var left = shopSwiper.getTranslate();
			// shopSwiper.params.speed = 0;
			shopSwiper.autoplay.pause();
			shopSwiper.autoplay.running = false;
			clearTimeout(shopSwiper.autoplay.timeout);
			shopSwiper.transitionEnd();
			shopSwiper.update();

			shopSwiper.params.speed = 500;
			shopSwiper.setTranslate(left);
			shopSwiper.update();

			shopSwiper.$wrapperEl[0].style.transform = 'translateX(' + shopSwiper.getTranslate() + 'px)';

			// shopSwiper.$wrapperEl[0].style.transform = 'translateX(' + shopSwiper.getTranslate() + 'px)';
			// shopSwiper.$wrapperEl[0].style.transitionDuration = '0ms';
			// shopSwiper.setTranslate(shopSwiper.getTranslate());
		});

		document.querySelector('.js-slider').addEventListener('mouseleave', function (event) {
			shopSwiper.$el[0].classList.remove('is-not-scrolling');

			shopSwiper.params.speed = 500;
			shopSwiper.autoplay.running = true;
			shopSwiper.autoplay.run();
			shopSwiper.update();

			console.log(shopSwiper);
		});
	}
}
