import Swiper from 'swiper/swiper-bundle.min.js';

export default function () {
	// gallery
	var swiper = Swiper;
    var init = false;

    function swiperMode() {
		let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');

		if (mobile.matches) {
			if (!init) {
				init = true;
				swiper = new Swiper('.unit_gallery',
					{
						wrapperClass: 'unit_gallery__wrapper',
						slideClass: 'unit_gallery__item',
						slidesPerView: 'auto',
						centeredSlides: true,
						loop: true,
						navigation: {
							prevEl: '.unit_gallery .swiper-left',
							nextEl: '.unit_gallery .swiper-right',
						},
						pagination: {
							el: '.unit_gallery .swiper-pagination',
						},
					}
				);
			} else {
				swiper.destroy();
				init = false;
			}
		}
    }

	window.addEventListener('load', function () {
		swiperMode();
	});

	// window.addEventListener('resize', function () {
	// 	swiperMode();
	// });

	if (document.querySelector('.unit_menu__item')) {
		document.querySelectorAll('.unit_menu__item').forEach(function (menu) {
			menu.onclick = function (e) {
				var id = e.target.dataset.id;
				document.querySelectorAll('.unit_menu__item').forEach(function (item) {
					item.classList.remove('is-active');
					var id2 = item.dataset.id;
					document.querySelector('body').classList.remove('is-' + id2);
				});
				document.querySelector('body').classList.add('is-' + id);
				document.querySelector('body').classList.add('is-modal');
				document.querySelectorAll('.unit_menu__item[data-id=' + id + ']').forEach(function (item2) {
					item2.classList.toggle('is-active');
				});
				e.preventDefault();
			};
		});
	}

	if (document.querySelector('.unit_details__head_title')) {
		document.querySelectorAll('.unit_details__head_title').forEach(function (item) {
			item.onclick = function (e) {
				e.target.closest('.unit_details').classList.toggle('is-active');
				e.preventDefault();
			};
		});
	}

	if (document.querySelector('.js-avail-size')) {
		document.querySelectorAll('.js-avail-size').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();

				var id = e.target.dataset.id;

				document.querySelectorAll('.js-avail-size').forEach(function (link) {
					link.classList.remove('is-active');
				});
				e.target.classList.add('is-active');

				document.querySelectorAll('.unit_avail__list').forEach(function (list) {
					list.classList.remove('active');
				});
				document.querySelector('.unit_avail__list[data-id="' + id + '"]').classList.add('active');
			};
		});
	}

	if (document.querySelector('.unit_details__close')) {
		document.querySelectorAll('.unit_details__close').forEach(function (link) {
			link.onclick = function (e) {
				e.preventDefault();

				if (document.querySelector('.unit_menu__item')) {
					document.querySelectorAll('.unit_menu__item').forEach(function (item) {
						var id2 = item.dataset.id;
						document.querySelector('body').classList.remove('is-' + id2);
						item.classList.remove('is-active');
					});
					document.querySelector('body').classList.remove('is-modal');
				}
			};
		});
	}
}
