import lax from 'lax.js';

export default function () {
	lax.init();
	lax.addDriver('scrollY', function () {
		return window.scrollY;
    });

	lax.addElements('.footer__logotype', {
		scrollY: {
			blur: [
				['elInY', 'elCenterY', 'elOutY'],
				[20, 0, 0],
			]
		}
	});

	if (document.querySelector('.footer_menu__title')) {
		document.querySelectorAll('.footer_menu__title').forEach(function (item) {
			item.onclick = function (e) {
				e.target.closest('.footer_menu__col').classList.toggle('active');
			};
		});
	}
}
