export default function () {
	// Добавить в избранное
	if (document.querySelector('.js-like')) {
		document.querySelectorAll('.js-like').forEach(function (item) {
			item.onclick = function (e) {
				var id = e.target.dataset.id;
				var name = e.target.dataset.name;
				var price = e.target.dataset.price;
				var article = e.target.dataset.article;
				var category = e.target.dataset.category;
				var action;
				var note;

				if (e.target.classList.contains('is-liked')) {
					e.target.classList.remove('is-liked');
					action = 'remove';

					if (document.querySelector('.page').classList.contains('page--wishlist')) {
						e.target.closest('.catalog').remove();
						if (!document.querySelector('.catalog')) {
							document.location.reload();
						}
					}

					setTimeout(function () {
						// global.app.notifications.shift();
					}, 2000);
				} else {
					e.target.classList.add('is-liked');
					action = 'add';

					window.dataLayerGA = window.dataLayerGA || [];
					window.dataLayerGA.push({
						'event': 'add_to_wishlist',
						'ecommerce': {
							'items': [{
								'item_name': name,
								'item_id': article,
								'price': price,
								'item_brand': 'Monochrome',
								'item_category': category,
								'index': 1,
								'quantity': '1'
							}]
						}
					});

					document.querySelector('body').classList.add('is-wishlist-open');
					document.querySelector('.js-header-wishlist').classList.add('is-activing');

					setTimeout(function () {
						document.querySelector('body').classList.remove('is-wishlist-open');
						document.querySelector('.js-header-wishlist').classList.remove('is-activing');
					}, 2000);
				}

				try {
					fetch('/ajax/wishlist', {
						headers: {
							'Content-Type': 'application/json; charset=utf-8'
						},
						method: 'POST',
						body: JSON.stringify({
							action: action,
							id: id
						})
					}).then(function (response) {
						return response.text().then(function (text) {
							var data = JSON.parse(text);
							if (data.count > 0) {
								document.querySelector('.js-header-wishlist').classList.add('is-active');
							} else {
								document.querySelector('.js-header-wishlist').classList.remove('is-active');
							}

							if (document.querySelector('.page').classList.contains('page--wishlist')) {
								if (document.querySelectorAll('.wishlist_list .catalog').length === 0 && document.querySelectorAll('.wishlist_catalog_list .catalog').length === 0) {
									document.location.reload(true);
								}
							}
						});
					});
				} catch (error) {
					console.error('Error:', error);
				}
				return false;
			};
		});
	}
}
