/* global Vue, VueTheMask, fbq, ymaps */
import AutoComplete from '@tarekraafat/autocomplete.js';
import 'regenerator-runtime/runtime';

export default function () {
	var myMap;
	function init() {
		myMap = new ymaps.Map('map', {
			center: [55.837182, 37.491341],
			zoom: 13,
			controls: ['zoomControl']
		}, {
			suppressMapOpenBlock: true
		});
		myMap.controls.remove('routeEditor');
	}

	// Быстрая корзина
	if (document.querySelector('#orderDelivery')) {
		window.order1 = new Vue({
			el: '#orderDelivery',
			data: {
				shipping: 1,
				delivery: 1,
				weekend: 0,
				pickpoint: 1,
				deliveryCourier: false,
				deliveryExpressCourier: false,
				deliveryPoint: false,
				dateCourier: false,
				dateExpressCourier: false,
				datePoint: false,
				calc: false,
				cdektype: 0,
				hint: ''
			},
			mounted: function () {
				this.shipping = document.querySelector('input[name=shipping]').value;
				this.delivery = document.querySelector('input[name=delivery]').value;
				this.weekend = document.querySelector('input[name=weekend]').value;
			},
			methods: {
				changeShipping: function (value, event) {
					event.preventDefault();
					this.shipping = value;
					document.querySelector('input[name=shipping]').value = value;

					if (value === 0) {
						document.querySelector('#address').required = false;
						document.querySelector('#addressField').style.display = 'none';
					} else {
						document.querySelector('#addressField').style.display = 'block';
					}

					if (value === 1) {
						document.querySelector('#address').required = true;
					}

					if (value === 2) {
						document.querySelector('#address').required = true;
						document.querySelector('#pickpointWrap').style.display = 'block';
					} else {
						document.querySelector('#pickpointWrap').style.display = 'none';
					}

					if (value === 2) {
						if (document.querySelector('input[name=pickpoint_id]').value > 0) {
							document.querySelector('.form__action button').disabled = false;
						} else {
							document.querySelector('.form__action button').disabled = true;
						}
					} else {
						document.querySelector('.form__action button').disabled = false;
					}
				}
			}
		});

		if (document.querySelector('#map')) {
			ymaps.ready(init);
		}

		if (document.querySelector('#address')) {
			const autoCompleteJS = new AutoComplete({
				selector: '#address',
				placeHolder: '',
				data: {
					src: async (query) => {
						try {
							var source;
							if (window.order1.shipping === 2) {
								source = await fetch('/ajax/address?q=' + query);
							} else {
								source = await fetch('/ajax/address?q=' + query + '&errors=true');
							}

							const data = await source.json();
							return data;
						} catch (error) {
							return error;
						}
					},
					keys: ['name'],
				},
				searchEngine: (query, record) => {
					return record;
				},
				resultsList: {
					element: (list, data) => {
						//
					},
					noResults: true
				},
				resultItem: {
					element: (item, data) => {
						//
					}
				},
				events: {
					input: {
						focus: () => {
							if (autoCompleteJS.input.value.length) {
								autoCompleteJS.start();
							}
						}
					}
				}
			});

			autoCompleteJS.input.addEventListener('navigate', function (event) {
				var data = event.detail;
				var value = data.selection.value.name;
				autoCompleteJS.input.value = value;
			});

			autoCompleteJS.input.addEventListener('selection', function (event) {
				var data = event.detail;
				autoCompleteJS.input.blur();
				var value = data.selection.value.name;
				autoCompleteJS.input.value = value;

				var error = data.selection.value.error;
				if (error) {
					document.querySelector('#address').closest('.field').classList.add('field--error');
					document.querySelector('#address').closest('.field').querySelector('.field__error').innerHTML = data.selection.value.errorText;
				} else {
					document.querySelector('#address').closest('.field').classList.remove('field--error');
				}

				var lat = data.selection.value.geo_lat;
				var lon = data.selection.value.geo_lon;

				var postal = data.selection.value.postal;
				var country = data.selection.value.country;
				var region = data.selection.value.region;
				var city = data.selection.value.city;
				var street = data.selection.value.street;
				var house = data.selection.value.house;
				var block = data.selection.value.block;
				var flat = data.selection.value.flat;
				var kladr = data.selection.value.kladr;
				window.order1.hint = data.selection.value.hint;

				window.order1.delivery = data.selection.value.delivery;
				document.querySelector('input[name=delivery]').value = data.selection.value.delivery;
				document.querySelector('input[name=postcode]').value = postal;
				document.querySelector('input[name=country]').value = country;
				document.querySelector('input[name=region]').value = region;
				document.querySelector('input[name=city]').value = city;
				document.querySelector('input[name=street]').value = street;
				document.querySelector('input[name=house]').value = house;
				document.querySelector('input[name=building]').value = block;
				if (document.querySelector('input[name=flat]')) {
					document.querySelector('input[name=flat]').value = flat;
				}
				document.querySelector('input[name=kladr]').value = kladr;
				window.cart.postcode = postal;

				fetch('/ajax/postal?q=' + kladr)
					.then((response) => {
						return response.json();
					})
					.then((data2) => {
						if (data2.cdek) {
							document.querySelector('input[name=cdek]').value = data2.cdek;
							window.cart.list();
						}
					});

					fetch('/ajax/pickpoints?lat=' + lat + '&lon=' + lon)
						.then((response) => {
							return response.json();
						})
						.then((data2) => {
							if (data2) {
								myMap.setCenter([lat, lon]);
								myMap.geoObjects.removeAll();

								data2.forEach(function (item) {
									var point = new ymaps.Placemark(
										[item.geo_lat, item.geo_lon], {},
										{
											preset: 'islands#icon',
											iconColor: '#000'
										}
									);
									point.events.add(['click'], function (e) {
										document.querySelector('.pickpoint_address').style.display = 'block';
										document.querySelector('.delivery__address').innerHTML = item.address;
										document.querySelector('.delivery__text').innerHTML = item.description;
										document.querySelector('textarea[name=pickpoint]').value = item.name + ' ' + item.address;
										document.querySelector('input[name=city_id]').value = item.city_id;
										document.querySelector('input[name=pickpoint_id]').value = item.id;
										document.querySelector('input[name=delivery_id]').value = item.delivery_id;

										document.querySelector('.form__action button').disabled = false;

										myMap.geoObjects.each(function (geoObject) {
											geoObject.options.set('iconColor', '#000');
										});

										e.get('target').options.set('iconColor', '#f00');
									});
									myMap.geoObjects.add(point);
								});
							}
						});
			});
		}
	}
}
