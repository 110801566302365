import Swiper from 'swiper/swiper-bundle.min.js';

export default function () {
	if (document.querySelector('.js-hero')) {
		document.querySelectorAll('.js-hero').forEach(function (item) {
			var _self = item;
			var heroSwiper = new Swiper(item, {
				slidesPerView: 'auto',
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: true
				},
				navigation: {
					prevEl: '.swiper-left',
					nextEl: '.swiper-right',
				},
				pagination: {
					el: '.swiper-pagination',
				},
				on: {
					init: function (swiper) {
						item.classList.add('swiper-taped');
					},
					touchStart: function (swiper, event) {
						item.classList.remove('swiper-taped');
					},
				}
			});
		});
	}
}
