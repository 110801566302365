export default function () {
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var headerElement = document.querySelector('.header');
	var navbarHeight = headerElement.offsetHeight;

	if (document.querySelector('.js-nav-catalog')) {
		document.querySelector('.js-nav-catalog').onmouseover = function (e) {
			document.querySelector('body').classList.remove('is-nav-info');
			document.querySelector('body').classList.add('is-nav-catalog');
		};
	}

	if (document.querySelector('.js-lang')) {
		document.querySelector('.js-lang').onclick = function (e) {
			document.querySelector('body').classList.toggle('is-lang');
		};
	}

	if (document.querySelector('.js-nav-info')) {
		document.querySelector('.js-nav-info').onmouseover = function (e) {
			document.querySelector('body').classList.remove('is-nav-catalog');
			document.querySelector('body').classList.add('is-nav-info');
		};
	}

	if (document.querySelector('.page__bg')) {
		document.querySelector('.page__bg').onmouseover = function (e) {
			document.querySelector('body').classList.remove('is-nav-catalog');
			document.querySelector('body').classList.remove('is-nav-info');
		};
		document.querySelector('.page__bg').onclick = function (e) {
			if (document.querySelector('.unit_menu__item')) {
				document.querySelectorAll('.unit_menu__item').forEach(function (item) {
					var id2 = item.dataset.id;
					document.querySelector('body').classList.remove('is-' + id2);
					item.classList.remove('is-active');
				});
				document.querySelector('body').classList.remove('is-modal');
			}
		};
	}

	function hasScrolled() {
		var st = window.scrollY;
		var top = document.querySelector('.page').offsetTop;

		if (Math.abs(lastScrollTop - st) <= delta) {
			return;
		}

		if (st > top) {
			document.querySelector('body').classList.add('nav-scroll');
		} else {
			document.querySelector('body').classList.remove('nav-scroll');
		}

		if (st > lastScrollTop && st > navbarHeight) {
			document.querySelector('body').classList.remove('nav-down');
			document.querySelector('body').classList.add('nav-up');
		} else {
			var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
			if (st + window.innerHeight + top < documentHeight) {
				document.querySelector('body').classList.remove('nav-up');
				document.querySelector('body').classList.add('nav-down');
			}
		}

		lastScrollTop = st;
	}

	window.addEventListener('scroll', function (e) {
		didScroll = true;
		hasScrolled();
	});

	setInterval(function () {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	if (document.querySelector('.js-nav')) {
		document.querySelectorAll('.js-nav').forEach(function (item) {
			item.onclick = function (e) {
				document.querySelector('body').classList.toggle('is-nav');
				e.preventDefault();
			};
		});
	}
}
