/* global cp */
import {animatedScrollTo} from 'es6-scroll-to';
import Swiper from 'swiper/swiper-bundle.min.js';

export default function () {
	// gallery
	var swiper = Swiper;
    var init = false;
    var color = 'pink';

    function swiperMode() {
		let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');

		if (mobile.matches) {
			if (!init) {
				init = true;
				swiper = new Swiper('.giftcard__container',
					{
						wrapperClass: 'giftcard__wrapper',
						slideClass: 'giftcard',
						slidesPerView: 'auto',
						centeredSlides: true,
						loop: true,
						slideToClickedSlide: true,
						on: {
							slideChange: function (object) {
								color = object.slides[object.activeIndex].dataset.color;
								document.querySelector('input[name="cert_color"]').value = color;
							}
						}
					}
				);
			} else {
				swiper.destroy();
				init = false;
			}
		}
    }

	if (document.querySelector('.giftcard')) {
		document.querySelectorAll('.giftcard').forEach(function (item) {
			item.onclick = function (e) {
				color = e.target.dataset.color;
				document.querySelector('input[name="cert_color"]').value = color;
				if (document.querySelector('.giftcard.active')) {
					document.querySelector('.giftcard.active').classList.remove('active');
				}
				e.target.classList.add('active');

				if (document.querySelector('.sections section form')) {
					var box = document.querySelector('.sections section form').getBoundingClientRect();
					var body = document.body;
					var docEl = document.documentElement;
					var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
					var clientTop = docEl.clientTop || body.clientTop || 0;
					var top = box.top + scrollTop - clientTop - 60 - (document.querySelector('.giftcard__wrapper').offsetHeight / 2);
					animatedScrollTo(parseInt(top, 1));
				}
			};
		});
		document.querySelector('.form_giftcard').onsubmit = function (e) {
			var sum = document.querySelector('input[name=sum]').value;
			if (sum < 1 || sum > 150000) {
				document.querySelector('.field--sum').classList.add('field--error');
			} else {
				document.querySelector('.field--sum').classList.remove('field--error');
				try {
					var widget = new cp.CloudPayments();
					var formData = new FormData(event.target);

					fetch('/ajax/gift', {
						headers: {
							'Content-Type': 'application/json; charset=utf-8'
						},
						method: 'POST',
						body: JSON.stringify(Object.fromEntries(formData))
					}).then(function (response) {
						return response.text().then(function (text) {
							var data = JSON.parse(text);

							var timeTo = Math.floor(new Date().getTime() / 1000) + (60 * 20);

							setInterval(function () {
								var time = Math.floor(new Date().getTime() / 1000);

								if (timeTo <= time)	{
									document.location = '/cart/processing';
								}
							}, 1000);

							widget.charge(
								{
									publicId: 'pk_da1cbff5ce319ca484a7c4743ecf3',
									description: data.name,
									amount: data.sum,
									currency: 'RUB',
									invoiceId: data.id,
									accountId: data.email,
									email: data.email,
									skin: 'mini',
									data: {
										'cloudPayments': {
											'CustomerReceipt': data.receipt
										}
									}
								},
								function (options) {
									document.location = '/order/' + data.slug;
								},
								function (reason, options) {
									document.location = '/cart/processing';
								}
							);
						});
					});
				} catch (error) {
					console.log(error);
				}
			}
			e.preventDefault();
		};
	}

	window.addEventListener('load', function () {
		swiperMode();
	});
}
