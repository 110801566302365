export default function () {
	if (document.querySelector('.js-catalog-show')) {
		document.querySelectorAll('.js-catalog-show').forEach(function (item) {
			item.onclick = function (e) {
				e.preventDefault();
				if (e.target.closest('.section').querySelector('.catalog--hide')) {
					e.target.closest('.section').querySelectorAll('.catalog--hide').forEach(function (product) {
						product.classList.remove('catalog--hide');
					});
				}
				if (document.querySelector('.catalog_link--more')) {
					document.querySelector('.catalog_link--more').remove();
				}
				if (document.querySelector('.catalog_link--all')) {
					document.querySelector('.catalog_link--all').style.display = 'block';
				}
			};
		});
	}
}
